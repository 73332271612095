import React, { useEffect } from "react";
import "./refundPolicy.scss";
import CommonBanner from "../../components/CommonBanner/CommonBanner";
import { images } from "../../source";

const RefundPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <CommonBanner
        text1="Refund Policy"
        disable
        title="REFUND <br />POLICY"
        image={images.termsBanner.image}
      />

      <section className="refund_sec1">
        <div className="my_container">
          <div className="text_container">
            <h2 className="main_head heading">REFUND AND RETURN POLICY</h2>
          </div>

          <div className="text_container">
            <h4 className="heading">Return</h4>
            <p className="description">
              Product must be returned to us within 7 days from the date it has
              been delivered to the customer. Product must be returned with all
              tags attached in its original condition along with all packing
              material, courier receipt, invoice & other papers.
            </p>
          </div>

          <div className="text_container">
            <h4 className="heading">Refund</h4>
            <p className="description">
              Once the Product is received to the company successfully, upGrad
              Education Private Limited will instantly initiate the refund to
              your source account or chosen method of refund.
            </p>
          </div>

          <div className="text_container">
            <h4 className="heading">
              Refund and Cancellation for Service Provider Company
            </h4>
            <p className="description">
              Due to service providers in nature “NO REFUND”, “NO CANCELLATION”
              will be entertained once the Payment has been made.
            </p>
          </div>

          <div className="text_container">
            <h4 className="heading">Cancellation Policy</h4>
            <p className="description">
              Please note an order can only be canceled within 24 hours of
              placing the order. Once the order is processed after 24 hours, no
              cancellation request will be entertained. However return is
              possible for all orders/products. OR Customers can CANCEL order
              only before the Order has been shipped/Dispatched. After the
              Product/s have been shipped, The Customer CANNOT Cancel the
              Orders. However return is possible for all orders/products.
            </p>
          </div>

          <div className="text_container">
            <h4 className="heading">Shipping &amp; Delivery Policies -</h4>
            <p className="description">
              upGrad Education Private Limited ships its products to almost all
              parts of India. Orders placed will be shipped within 24* hrs. We
              ship on all days except Sunday and National Holidays. For all
              areas serviced by reputed couriers, the delivery time would be
              within 3 to 4 business days of shipping (business days exclude
              Sundays and other holidays). For other areas the products will be
              shipped through delivery agent and may take 1-2 weeks depending on
              location. At times there might be unexpected delays in the
              delivery of your order due to unavoidable and undetermined
              logistics challenges beyond our control for which upGrad Education
              Private Limited is not liable and would request its users to
              cooperate as upGrad Education Private Limitedcontinuously tries to
              nought such instances. Also, upGrad Education Private Limited
              reserves the right to cancel your order at its sole discretion in
              cases where it takes longer than usual delivery time or the
              shipment is physically untraceable and refund the amount paid for
              cancelled product(s) to your source account.
            </p>
          </div>

          <div className="text_container">
            <h4 className="heading">Contact Us:-</h4>
            <p className="description">upGrad Education Private Limited</p>
            <p className="description">
              upGrad Living, Sunteck
              <br />
              BKC 51, LBS Marg, Kurla West,
              <br />
              Mumbai - 400070
              <br />
            </p>
            <p className="description">
              Mob:{" "}
              <a href="tel:+919004106218" style={{ color: "inherit" }}>
                +91 90041 06218
              </a>
            </p>
            <p className="description">
              Email:{" "}
              <a href="mailto:living@upgrad.com" style={{ color: "blue" }}>
                living@upgrad.com
              </a>
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default RefundPolicy;
