import React from "react";
import { Link } from "react-router-dom";
import {
  accommodationURL,
  contactURL,
  exploreAroundYouURL,
  homeURL,
  privacypolicyURL,
  refundPolicyURL,
  termsandconditionURL,
} from "../../helpers/paths";
import {
  callIconRed,
  coloredLogo,
  footermail,
  images,
  mailicon,
} from "../../source";
import "./footer.scss";

const Footer = () => {
  const today = new Date();
  const scrollHandler = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  return (
    <React.Fragment data-aos="fade">
      <section className="footer_sec1">
        <div className="my_container">
          <div className="row footer_main">
            <div className="col-md-6 col-lg-4">
              <div className="logo_wrapper">
                <a href={homeURL}>
                  <img
                    className="footer_logo"
                    onClick={scrollHandler}
                    src={coloredLogo}
                    alt="logo"
                  />
                </a>
                <p className="description">
                  <a
                    href="https://goo.gl/maps/hYDfWJgLUkjZbJUL6"
                    target="_blank"
                    rel="noreferrer"
                    className="add_link"
                  >
                    upGrad Living, Sunteck <br /> BKC 51, LBS Marg, Kurla West,{" "}
                    <br />
                    Mumbai - 400070
                  </a>
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-2">
              <div className="links_wrapper">
                <h4 className="link_heading">Quick Links</h4>
                <ul className="link_list">
                  <Link className="link" to={exploreAroundYouURL}>
                    <li>Explore Around You</li>
                  </Link>
                  {/* <Link className="link" to="/">
                    <li>Events</li>
                  </Link> */}
                  <Link className="link" to={contactURL}>
                    <li>Contact Us</li>
                  </Link>
                  <Link className="link" to={accommodationURL}>
                    <li>Accommodation</li>
                  </Link>
                </ul>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="links_wrapper links_wrapper2">
                <h4 className="link_heading">Information</h4>
                <ul className="link_list">
                  <Link className="link" to={privacypolicyURL}>
                    <li>Privacy Policy</li>
                  </Link>
                  <Link className="link" to={termsandconditionURL}>
                    <li>Terms & Conditions</li>
                  </Link>
                  {/* <a className="link" href={refundURL} target="_blank" rel="noreferrer">
                    <li>Refund & Cancellation</li>
                  </a> */}
                  <Link className="link" to={refundPolicyURL}>
                    <li>Refund & Cancellation</li>
                  </Link>
                </ul>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="links_wrapper links_wrapper3">
                <h4 className="link_heading">Contact Us</h4>
                <p className="description">
                  For Newly Enrolled <br /> Students in Batch 2024: <br />
                  <br />
                  <span className="contact_no">
                    Abhijeet Patil: <br />
                    <img
                      src={callIconRed}
                      alt="call icon"
                      className="footer_icon"
                    />
                    <a href="tel:+919004106218">+91 90041 06218</a>
                  </span>
                </p>
                <ul className="link_list">
                  <a className="link mail_link" href="mailto:living@upgrad.com">
                    <li>
                      <img
                        // src={footermail}
                        src={mailicon}
                        alt="location"
                        className="footer_icon"
                      />
                      <div className="text_part">living@upgrad.com</div>
                    </li>
                  </a>
                </ul>
              </div>
            </div>
          </div>
          {/* <div className="store_wrapper">
            <p className="description">Download App on</p>
            <a href="/">
              <img
                src={images.appStore.image}
                alt={images.appStore.alt}
                className="social_icon fb_icon"
                loading="lazy"
              />
            </a>
            <a href="/">
              <img
                src={images.playStore.image}
                alt={images.playStore.alt}
                className="social_icon fb_icon playstore_icon"
                loading="lazy"
              />
            </a>
          </div> */}
        </div>
      </section>
      <section className="footer_sec2">
        <div className="my_container">
          <div className="copyright_flex">
            <p className="copyright image_disclaimer">
              <span>
                Copyright © {today.getFullYear()} | All Rights Reserved by ATLAS
              </span>
              *ALL IMAGES ARE ARTIST IMPRESSIONS
            </p>
            <p className="copyright">
              Crafted by{" "}
              <a
                className="togglehead_link"
                href="https://www.togglehead.in/"
                target="_blank"
                rel="noreferrer"
              >
                Togglehead
              </a>
            </p>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Footer;
